/* Add your variable customizations of layout here */
$xs: 280px !default;
$sm: 576px !default;
$nm: 600px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1300px !default;


$breakpoints: (
    'xs': $xs,
    'sm': $sm,
    'nm': $nm,
    'md': $md,
    'lg': $lg,
    'xl': $xl) !default;